import { Box, Card, CardContent, Chip, IconButton, InputAdornment, InputLabel, Link, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CategoryStatus } from '../../constants/common.constants';
import SearchIcon from '@mui/icons-material/Search';
import LanguageSelector from '../utils/LanguageSelector';
import ArticleIcon from '@mui/icons-material/Article';
import { useEffect, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import CancelIcon from '@mui/icons-material/Cancel';

const UserClaimsDetail = (props) => {
  const { claimDetail } = props;
  const { t } = useTranslation();
  const [certificate, setCertificate] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [lang, setLang] = useState('en');

  function getFileExtension(url) {
    const extensionRegex = /\.([a-zA-Z0-9]+)(?=\?|$)/;
    const match = extensionRegex.exec(url);
    if (match) {
      const extension = match[1].toLowerCase();
      return extension === 'pdf' || extension === 'doc' || extension === 'docx' || extension === 'csv' || extension === 'ppt' || extension === 'pptx' || extension === 'txt' || extension === 'xlsx'
        ? extension
        : '';
    }
    return '';
  }
  useEffect(() => {
    setCertificate(claimDetail?.certificates || []);
  }, [claimDetail]);
  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term.trim() === '') {
      setCertificate(claimDetail?.certificates);
    } else {
      const filtered = claimDetail?.certificates?.filter((cert) => cert.name.toLowerCase().includes(term.trim().toLowerCase()));
      setCertificate(filtered);
    }
  };
  return (
    <>
      <Card sx={{ boxShadow: 'none' }}>
        <CardContent>
          <Box>
            <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <Typography sx={{ fontWeight: '700', fontSize: '16px', color: '#0E1090' }}>{claimDetail?.claimName}</Typography>
              <Stack direction="row" spacing={1} my={1}>
                <Chip
                  variant="contained"
                  label={
                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                      {claimDetail?.claimStatus === CategoryStatus.verified ? (
                        <CheckCircleIcon sx={{ fontSize: '18px', color: '#' }} />
                      ) : claimDetail?.claimStatus === CategoryStatus.proven ? (
                        <CheckCircleIcon sx={{ fontSize: '18px', color: '#fff' }} />
                      ) : claimDetail?.claimStatus === CategoryStatus.declared ? (
                        <ChatBubbleOutlineIcon sx={{ fontSize: '18px', color: '#fff' }} />
                      ) : (
                        <CancelIcon sx={{ fontSize: '18px', color: '#fff' }} />
                      )}
                      {claimDetail?.claimStatus}
                    </div>
                  }
                  sx={{
                    borderRadius: '25px',
                    color: '#fff',
                    bgcolor:
                      claimDetail?.claimStatus === CategoryStatus.verified
                        ? '#28a745'
                        : claimDetail?.claimStatus === CategoryStatus.proven
                        ? '#218838'
                        : claimDetail?.claimStatus === CategoryStatus.declared
                        ? '#1e7e34'
                        : '#c82333',
                  }}
                />
              </Stack>
            </Box>
          </Box>
        </CardContent>
      </Card>
      <Card sx={{ boxShadow: 'none', mt: 3 }}>
        <CardContent>
          {claimDetail?.claimStatus === CategoryStatus.rejected ? (
            <>
              <InputLabel sx={{ color: '#000', fontWeight: '600', fontSize: '14px', cursor: 'pointer' }}>{t('reason')}</InputLabel>
              <TextField
                fullWidth
                id="standard-basic"
                variant="standard"
                value={claimDetail?.reason}
                InputProps={{
                  readOnly: true,
                }}
              />
            </>
          ) : null}
          <InputLabel sx={{ color: '#000', fontWeight: '600', fontSize: '14px', cursor: 'pointer' }}>{t('label_assigned')}</InputLabel>
          <TextField
            fullWidth
            id="standard-basic"
            variant="standard"
            placeholder={t('label_name')}
            value={claimDetail?.label}
            InputProps={{
              readOnly: true,
            }}
          />

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 4 }}>
            <InputLabel sx={{ color: '#000', fontWeight: '600', fontSize: '14px' }}>{t('description') + '*'}</InputLabel>
            <LanguageSelector setLang={setLang} selectLang={lang} />
          </Box>
          <TextField
            fullWidth
            id="standard-basic"
            variant="standard"
            placeholder={t('description')}
            value={claimDetail?.description?.[lang] || ''}
            InputProps={{
              readOnly: true,
            }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 4 }}>
            <InputLabel sx={{ color: '#000', fontWeight: '600', fontSize: '16px' }}>{t('assigned_document')}</InputLabel>
            <TextField
              value={searchTerm}
              onChange={(e) => handleSearchChange(e)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '40px',
                },
              }}
              size="small"
              variant="outlined"
              placeholder={t('search')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <InputLabel sx={{ mt: 2, mb: 2, color: 'gray', fontWeight: '600', fontSize: '12px' }}>{t('document_attach')}</InputLabel>
          <Box sx={{ display: 'flex', flexWrap: 'nowrap', width: '100%', gap: '10px' }}>
            {certificate?.map((item) => (
              <>
                {(item?.urls?.toString().includes('.pdf') || item?.urls?.toString().includes('.doc') || item?.urls?.toString().includes('.docx') || item?.urls?.toString().includes('.csv')) && (
                  <Tooltip title={item?.name} placement="top">
                    <a className="download1" href={item?.urls} target="_blank" rel="noopener noreferrer">
                      <Box sx={{ cursor: 'pointer' }} minWidth={'100px'}>
                        <Box
                          sx={{
                            height: '120px',
                            width: '100px',
                            bgcolor: '#fff',
                            borderRadius: '8px',
                            textAlign: 'justify',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'relative',
                            border: '1px solid #0e1090',
                            cursor: 'pointer',
                          }}
                        >
                          <ArticleIcon style={{ fontSize: '50px' }} />
                          <span style={{ fontSize: '14px', color: 'gray', textTransform: 'uppercase' }}>{getFileExtension(item.urls[0])}</span>
                          <Chip
                            label={item?.verificationStatus === CategoryStatus.verified ? 'Valid' : item?.verificationStatus === CategoryStatus.pending ? 'Yet to be valid' : 'Not Valid'}
                            color={item?.verificationStatus === CategoryStatus.verified ? 'success' : item?.verificationStatus === CategoryStatus.pending ? 'warning' : 'error'}
                            sx={{
                              height: '20px',
                              color: '#fff',
                              position: 'absolute',
                              bottom: '3px',
                              left: '50%',
                              transform: 'translateX(-50%)',
                              fontSize: '10px',
                            }}
                          />
                        </Box>

                        <Typography
                          sx={{
                            fontSize: '12px',
                            color: '#000',
                            textAlign: 'center',
                            px: 1,
                            alignItems: 'center',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            wordWrap: 'break-word',
                            width: '100px',
                          }}
                        >
                          {item?.name}
                        </Typography>
                      </Box>
                    </a>
                  </Tooltip>
                )}
                {(item?.urls?.toString().includes('.jpg') || item?.urls?.toString().includes('.png') || item?.urls?.toString().includes('.jpeg')) && (
                  <Tooltip title={item?.name} placement="top">
                    <a className="download1" href={item?.urls} rel="noopener noreferrer">
                      <Box sx={{ cursor: 'pointer' }}>
                        <Box sx={{ position: 'relative' }}>
                          <img src={item?.urls} alt="" height={'120px'} width={'100px'} style={{ borderRadius: '8px', border: '1px solid #0e1090' }} />
                          <Chip
                            label={item?.verificationStatus === CategoryStatus.verified ? 'Valid' : item?.verificationStatus === CategoryStatus.pending ? 'Yet to be valid' : 'Not Valid'}
                            color={item?.verificationStatus === CategoryStatus.verified ? 'success' : item?.verificationStatus === CategoryStatus.pending ? 'warning' : 'error'}
                            sx={{
                              height: '20px',
                              color: '#fff',
                              position: 'absolute',
                              bottom: '3px',
                              left: '50%',
                              transform: 'translateX(-50%)',
                              fontSize: '10px',
                            }}
                          />
                        </Box>
                        <Typography
                          sx={{
                            fontSize: '12px',
                            color: '#000',
                            textAlign: 'center',
                            px: 1,
                            alignItems: 'center',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            wordWrap: 'break-word',
                            width: '100px',
                          }}
                        >
                          {item?.name}
                        </Typography>
                      </Box>
                    </a>
                  </Tooltip>
                )}
              </>
            ))}
          </Box>
        </CardContent>
      </Card>
    </>
  );
};
export default UserClaimsDetail;
