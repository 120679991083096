import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '../service/http.service';
import { RestUrlsConstants } from '../constants/rest-urls.constants';

const initialState = {
  questionForm: [],
  loading: false,
  error: null,
};

export const fetchQuestionForm = createAsyncThunk('question/form', async (id, { rejectWithValue }) => {
  try {
    const response = await httpService.get(`${RestUrlsConstants.formUrl}${id}`, {
      headers: {
        Authorization: localStorage.getItem('Authorization'),
      },
    });

    return response?.data?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data || error.message);
  }
});

const questionFormSlice = createSlice({
  name: 'questionForm',
  initialState,
  reducers: {
    clearState: (state) => {
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuestionForm.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchQuestionForm.fulfilled, (state, action) => {
        state.loading = false;
        state.questionForm = action.payload;
      })
      .addCase(fetchQuestionForm.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default questionFormSlice.reducer;
