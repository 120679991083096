import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { Box, Button, Checkbox, Grid, IconButton, InputAdornment, InputLabel, Pagination, Snackbar, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CommonInput from '../utils/CommonInput';
import LanguageSelector from '../utils/LanguageSelector';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useEffect, useState } from 'react';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import Loader from '../utils/loader';
import httpService from '../../service/http.service';
import { RestUrlsConstants } from '../../constants/rest-urls.constants';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSupplyChainData } from '../../features/chainSlice';
import { CommonButton } from '../utils/CommonButton';
import { fetchProducts, fetchSearchProducts } from '../../features/productSlice';
import { useParams } from 'react-router-dom';
import { fetchChainById } from '../../features/ChainByIdSlice';
import { decryptId } from '../utils/encryptDecrypt';
import AlertDialog from '../utils/AlertDialog';
import useCheckOnlineStatus from '../../hooks/useOnlineCheckStatus';


export default function UpdateSupplyChain(props) {
  const { selectedSupplyChain, updateSupplyChain, setUpdateSupplyChain } = props;
  const { t } = useTranslation();
  const params = useParams();
  let currentPage=params?.page;
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [supplyChainData, setSupplyChainData] = useState({
    name: '',
    description: '',
    brandProduct: [],
  });
  const [selectAll, setSelectAll] = useState(false);
  const [lang, setLang] = useState('');
  const [errors, setErrors] = useState({ name: '', description: '' });
  const [openAlertChainDialog, setOpenAlertChainDialog] = useState(false);
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const checkOnlineStatus = useCheckOnlineStatus(setSnackBar);
  const { products, productLoading } = useSelector((state) => state.productEngine);
  const [page, setPage] = useState(1);
  const itemsPerPage = 12;
  const pageCount = Math.ceil(products?.totalRecords / itemsPerPage);
  const currentPageData = products?.products?.slice((page - 1) * itemsPerPage, page * itemsPerPage);


  const handleChange = (event, value) => {
    setPage(value);
  };
  useEffect(() => {
    dispatch(fetchProducts({ page, itemsPerPage }));
  }, [dispatch, page]);

  useEffect(() => {
    setSupplyChainData((prevData) => ({
      ...prevData,
      name: selectedSupplyChain?.name,
      brandProduct: selectedSupplyChain?.products?.map((product) => ({
        id: product.id,
        name: product.title?.value,
      })),
    }));
  }, [selectedSupplyChain, lang]);
  useEffect(() => {
    setSupplyChainData((prevData) => ({
      ...prevData,
      description: selectedSupplyChain?.description ? selectedSupplyChain?.description?.[lang] : selectedSupplyChain?.description?.value,
    }));
  }, [selectedSupplyChain, lang]);

  const handleCloseModal = () => {
    setUpdateSupplyChain(false);
    setSupplyChainData({
      name: '',
      description: '',
      brandProduct: [],
    });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { name: '',};
    if (!supplyChainData?.name?.trim()) {
      newErrors.name = t('name_is_required');
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };

  const handleCreateSupplyChain = async () => {
    if (!checkOnlineStatus()) return; 
    if (!validateForm()) return;
    try {
      const payload = {
        name: supplyChainData?.name,
        description: supplyChainData?.description,
        lang: lang,
        products: supplyChainData?.brandProduct,
      };
      const response = await httpService.put(`${RestUrlsConstants.supplyChain}/${selectedSupplyChain?.id}`, payload, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'application/json',
        },
      });
      if (response?.data?.status) {
        setUpdateSupplyChain(false);
        setSupplyChainData({
          name: '',
          description: '',
          brandProduct: [],
        });
        // dispatch(fetchSupplyChainData(currentPage));
        dispatch(fetchChainById(decryptId(params.id)));
        // setSnackBar({ open: true, message: response?.data?.message });
      }
    } catch (error) {
      console.log(error);
      // setSnackBar({ open: true, message: error?.response?.data?.message });
    }
  };

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term.trim() === '') {
      dispatch(fetchProducts({ page, itemsPerPage }));
    } else {
      dispatch(fetchSearchProducts({ term }));
    }
  };

  const selectProduct = (e, val) => {
    if (e.target.checked) {
      setSupplyChainData((prevData) => ({
        ...prevData,
        brandProduct: [...(prevData.brandProduct || []), { id: val?.id, name: val?.title?.value }],
      }));
    } else {
      setSupplyChainData((prevData) => ({
        ...prevData,
        brandProduct: (prevData.brandProduct || []).filter((product) => product.id !== val?.id),
      }));
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSupplyChainData((prevData) => ({
        ...prevData,
        brandProduct: [],
      }));
    } else {
      setSupplyChainData((prevData) => ({
        ...prevData,
        brandProduct: products.products?.map((product) => ({
          id: product.id,
          name: product.title?.value,
        })),
      }));
    }
    setSelectAll(!selectAll);
  };

  const isProductSelected = (id) => {
    return supplyChainData?.brandProduct?.some((product) => product.id === id);
  };

  const handleRemoveSelectedProduct = () => {
    setSupplyChainData((prevData) => ({
      ...prevData,
      brandProduct: [],
    }));
    setOpenAlertChainDialog(false);
  };
  const snackbarClose = () => {
    setSnackBar({ open: false, message: '' });
  };
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  return (
    <>
      <Dialog sx={{ zIndex: 100 }} maxWidth={'md'} open={updateSupplyChain} onClose={handleCloseModal}>
        <DialogContent sx={{ bgcolor: '#fff', width: '800px', position: 'relative', py: 0, pb: 2 }}>
          <DialogTitle sx={{ color: '#0e1090', fontWeight: 'bold', pl: 0 }}>{t('edit_supply_chain')}</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'black',
            }}
          >
            <CloseIcon />
          </IconButton>
          <CommonInput
            label={t('name') + '*'}
            type="text"
            placeholder={t('name')}
            variant="standard"
            value={supplyChainData?.name}
            handleChange={(e) => setSupplyChainData({ ...supplyChainData, name: e.target.value })}
            error={!!errors.name}
            helperText={errors.name ? errors.name : t('name_eg')}
          />
          <Box mt={4} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <InputLabel sx={{ color: '#000', fontWeight: '600', fontSize: '14px', cursor: 'pointer' }}>{t('description')}</InputLabel>
            <LanguageSelector selectLang={lang} setLang={setLang} />
          </Box>
          <TextField
            fullWidth
            value={supplyChainData?.description}
            onChange={(e) => setSupplyChainData({ ...supplyChainData, description: e.target.value })}
            type="text"
            placeholder={t('description')}
            variant={'standard'}
          />
          <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2 }}>
            <Pagination
              sx={{
                '& .MuiPaginationItem-root': {
                  color: 'gray',
                },
                '& .MuiPaginationItem-root.Mui-selected': {
                  backgroundColor: '#EC895A',
                  color: '#fff',
                },
              }}
              count={pageCount}
              page={page}
              onChange={handleChange}
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 3 }}>
            <Typography sx={{ color: '#0e1090', fontWeight: 'bold' }}>{t('select_products')}</Typography>
            <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <CommonButton buttonName={selectAll ? t('deselect_all') : t('select_all')} handleClick={handleSelectAll} />
              <TextField
                size="small"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '20px',
                  },
                }}
                variant="outlined"
                placeholder={t('search')}
                value={searchTerm}
                onChange={handleSearchChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <IconButton sx={{ bgcolor: '#0e1090', borderRadius: '30px', ':hover': { bgcolor: '#0e1090' } }}>
                <FilterListIcon sx={{ color: '#fff' }} />
              </IconButton>
            </Box>
          </Box>
          <Box display={'flex'} justifyContent={'space-between'} width={'48%'}>
            <CommonButton buttonName={selectAll ? t('deselect_all') : t('select_all')} handleClick={handleSelectAll} />
            <Button sx={{ bgcolor: 'gray', ':hover': { bgcolor: 'gray' }, color: '#fff', borderRadius: '20px', px: 1, textTransform: 'capitalize' }}>
              {`Selected: ${supplyChainData?.brandProduct?.length}`}
            </Button>
            <Button
              disabled={selectAll || supplyChainData?.brandProduct?.length <= 0}
              onClick={() => setOpenAlertChainDialog(true)}
              sx={{
                bgcolor: supplyChainData?.brandProduct?.length <= 0 || selectAll ? 'gray' : '#000',
                ':hover': { bgcolor: '#101010' },
                color: '#fff',
                borderRadius: '20px',
                px: 2,
                textTransform: 'capitalize',
              }}
            >
              {t('Unselect_All')}
            </Button>
          </Box>
          {productLoading ? (
            <Loader />
          ) : (
            <Grid mt={2} container spacing={2}>
              {currentPageData?.map((val, index) => (
                <Grid key={index} item xs={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Grid container bgcolor="#fff" p={1} borderRadius={2}>
                    <Grid item xs={3}>
                      <img src={val?.urls[0]} alt="" height={'40px'} width={'40px'} style={{ borderRadius: '10px' }} />
                    </Grid>
                    <Grid item xs={7}>
                      <Typography sx={{ color: '#000', fontSize: '14px', fontWeight: 'bold', display: 'flex', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {val?.title?.value}
                      </Typography>
                      <Typography sx={{ color: 'gray', fontSize: '12px', fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{val?.qrId}</Typography>
                    </Grid>
                    <Grid item xs={2} textAlign={'center'}>
                      <Checkbox {...label} icon={<CircleOutlinedIcon />} checkedIcon={<CircleIcon />} checked={isProductSelected(val?.id)} onChange={(e) => selectProduct(e, val)} />
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          )}
          <Box mt={2} display={'flex'} alignItems={'center'} justifyContent={'end'}>
            <Button onClick={() => handleCreateSupplyChain()} sx={{ color: '#fff', bgcolor: '#000', borderRadius: '30px', px: 4, ':hover': { bgcolor: '#000' } }}>
              {t('save')}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <AlertDialog
        btnName={t('yes')}
        title={t('remove_all_the_products_connected_to_this_claim')}
        description={t('are_you_sure_you_want_to_remove_all_products')}
        open={openAlertChainDialog}
        handleClick={handleRemoveSelectedProduct}
        handleCloseDialog={() => {
          setOpenAlertChainDialog(false);
        }}
      />
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        message={snackBar.message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={snackbarClose}
        className="snackBarColor"
        key="snackbar"
      />
    </>
  );
}
