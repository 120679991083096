import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import AmericaIcon from '../../images/icons/americaIcon';
import TurkeyIcon from '../../images/icons/trukeyIcon';
import FranceIcon from '../../images/icons/franceIcon';
import ItalyIcon from '../../images/icons/italyIcon';
import GermanyIcon from '../../images/icons/germanyIcon';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

function getStyles(code, selectedValues = [], theme, isMultiple) {
  const isSelected = isMultiple ? selectedValues.includes(code) : selectedValues === code;
  return {
    fontWeight: isSelected ? theme.typography.fontWeightMedium : theme.typography.fontWeightRegular,
  };
}

const languages = [
  { code: 'en', name: 'English', flag: <AmericaIcon /> },
  { code: 'tr', name: 'Türkçe', flag: <TurkeyIcon /> },
  { code: 'fr', name: 'Français', flag: <FranceIcon /> },
  { code: 'it', name: 'Italiano', flag: <ItalyIcon /> },
  { code: 'de', name: 'Deutsch', flag: <GermanyIcon /> },
];

export default function BrandLanguageSelector({
  placeHolder,
  singleSelectedValue = '',
  setSingleSelectedValue,
  multipleSelectedValues = [],
  setMultipleSelectedValues,
  multipleSelect = false,
  disabled = false,
  availableLanguages = [],
}) {
  const theme = useTheme();

  const handleChange = (event) => {
    const { value } = event.target;

    if (multipleSelect) {
      setMultipleSelectedValues(typeof value === 'string' ? value.split(',') : value);
    } else {
      // Update state for single selected value
      setSingleSelectedValue(value);
    }
  };

  const renderSelectedValues = (selected) => {
    if (!selected || (Array.isArray(selected) && selected.length === 0) || selected === '') {
      return <em>{placeHolder}</em>;
    }
    return Array.isArray(selected)
      ? languages
          .filter((lang) => selected.includes(lang.code))
          .map((lang) => lang.name)
          .join(', ')
      : languages.find((lang) => lang.code === selected)?.name || '';
  };

  const availableOptions = multipleSelect ? languages : languages.filter((lang) => availableLanguages.includes(lang.code));

  return (
    <FormControl sx={{ m: 1, width: 200 }} size="small">
      <Tooltip  title={multipleSelect || availableLanguages.length > 0 ? '' : 'First select multiple language'}>
        <Select
          displayEmpty
          multiple={multipleSelect}
          value={multipleSelect ? multipleSelectedValues : singleSelectedValue}
          onChange={handleChange}
          renderValue={(selected) => renderSelectedValues(selected)}
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': 'Without label' }}
          disabled={disabled}
          sx={{
            cursor: disabled ? 'not-allowed' : 'default',
            '.Mui-disabled': { cursor: 'not-allowed' },
          }}
        >
          <MenuItem disabled value="">
            <em>{multipleSelect ? 'Select-Multi-Languages' : 'Select-Default-Language'}</em>
          </MenuItem>
          {availableOptions.map((language) => (
            <MenuItem key={language.code} value={language.code} style={getStyles(language.code, multipleSelect ? multipleSelectedValues : singleSelectedValue, theme, multipleSelect)}>
              {multipleSelect && <Checkbox checked={multipleSelectedValues.includes(language.code)} />}
              <ListItemText primary={language.name} />
              {language.flag}
            </MenuItem>
          ))}
        </Select>
      </Tooltip>
    </FormControl>
  );
}
