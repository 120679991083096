import { Box, DialogContent, Grid, Input, InputLabel, MenuItem, Select, Snackbar, TextField, Typography } from '@mui/material';
import CommonDialog from '../utils/CommonDialog';
import { CommonButton } from '../utils/CommonButton';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import httpService from '../../service/http.service';
import { RestUrlsConstants } from '../../constants/rest-urls.constants';
import useCheckOnlineStatus from '../../hooks/useOnlineCheckStatus';

const UploadCSVFileDialog = (props) => {
  const { openUploadCSVSupplier, setOpenUploadCSVSupplier } = props;
  const { t } = useTranslation();
  const csvFileRef = useRef(null);
  const [csvError, setCsvError] = useState('');
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const checkOnlineStatus = useCheckOnlineStatus(setSnackBar);
  const [csvFile, setCsvFile] = useState([]);
  const [description, setDescription] = useState('');
  const [fileType, setFileType] = useState('');
  const [fileTypeError, setFileTypeError] = useState('');

  const snackbarClose = () => {
    setSnackBar({ open: false, message: '' });
  };

  const validateFiles = () => {
    let error = '';
    if (!fileType) {
      setFileTypeError('File type is required.');
      error = true;
    } else {
      setFileTypeError('');
    }

    if (csvFile.length === 0) {
      setCsvError('File is required.');
      error = true;
    } else {
      const invalidFiles = Array.from(csvFile).filter(file => file.type !== 'text/csv');
      if (invalidFiles.length > 0) {
        setCsvError('Please upload only CSV files.');
        error = true;
      } else {
        setCsvError('');
      }
    }
    
    return !error;
  };

  const handleFileChange = (event) => {
    setCsvFile(event.target.files);
    setCsvError('');
  };

  const handleClose = () => {
    setOpenUploadCSVSupplier(false);
    setCsvError('');
    setCsvFile([]);
    setDescription('');
    setFileType('');
    setFileTypeError('');
  };

  const uploadCSVFiles = async () => {
    if (!checkOnlineStatus()) return; 
    if (!validateFiles()) return;

    try {
      const formData = new FormData();
      formData.append('type', fileType);
      if(description!==""){
        formData.append('description', description);
      }

      Array.from(csvFile).forEach(file => formData.append('upload', file));

      const response = await httpService.post(`${RestUrlsConstants.CSVFILES}`, formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response?.data?.status) {
        setSnackBar({ open: true, message: response?.data?.message });
        handleClose();
      }
    } catch (error) {
      setSnackBar({ open: true, message: error?.response?.data?.message });
    }
  };

  return (
    <>
      <CommonDialog open={openUploadCSVSupplier} handleClose={handleClose} heading={t('upload_files')}>
        <DialogContent sx={{ width: '500px' }}>
          <Grid container spacing={2} sx={{ p: 2, borderRadius: '10px' }}>
            <Grid item xs={12}>
              <InputLabel sx={{ color: '#000', fontWeight: '600', fontSize: '14px', mb: 2 }}>{t('upload_CSV_file')}*</InputLabel>
              <Input fullWidth size="small" ref={csvFileRef} required type="file" accept=".csv" onChange={handleFileChange} />
              {csvError && (
                <Typography color="error" variant="body2">
                  {csvError}
                </Typography>
              )}
              <InputLabel sx={{ color: '#000', fontWeight: '600', fontSize: '14px', mb: 2, mt: 4 }}>{t('file_type')}*</InputLabel>
              <Select fullWidth size="small" value={fileType} variant="standard" onChange={(e) => setFileType(e.target.value)}>
                {[t('SUPPLIER'), t('SUPPLIER_CHAIN'), t('SUPPLIER_PRODUCT'), t('BRAND_PRODUCT')].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
              {fileTypeError && (
                <Typography color="error" variant="body2">
                  {fileTypeError}
                </Typography>
              )}
              <InputLabel sx={{ color: '#000', fontWeight: '600', fontSize: '14px', mb: 2, mt: 4 }}>{t('description')}</InputLabel>
              <TextField size="small" fullWidth type="text" variant="standard" placeholder={t('description')} value={description} onChange={(e) => setDescription(e.target.value)} />

            </Grid>
            <Grid item xs={12} mt={4}>
              <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                <CommonButton handleClick={uploadCSVFiles} buttonName={t('upload')} />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </CommonDialog>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        message={snackBar.message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={snackbarClose}
        className="snackBarColor"
        key="snackbar"
      />
    </>
  );
};

export default UploadCSVFileDialog;
