import { MenuItem, Select, FormControl } from '@mui/material';
import AmericaIcon from '../../images/icons/americaIcon';
import TurkeyIcon from '../../images/icons/trukeyIcon';
import FranceIcon from '../../images/icons/franceIcon';
import ItalyIcon from '../../images/icons/italyIcon';
import GermanyIcon from '../../images/icons/germanyIcon';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

const LanguageSelector = ({ setLang, selectLang, disable }) => {
  const user = useSelector((store) => store.user);
  
  useEffect(() => {
    setLang(user?.brand?.defaultLanguage);
  }, [user?.brand?.defaultLanguage]);

  const handleChange = (event) => {
    setLang(event.target.value);
  };

  const flagIcons = {
    en: <AmericaIcon />,
    tr: <TurkeyIcon />,
    fr: <FranceIcon />,
    it: <ItalyIcon />,
    de: <GermanyIcon />,
  };

  return (
    <FormControl>
      <Select
        disabled={disable}
        labelId="demo-select-small-label"
        id="demo-select-small"
        sx={{
          borderRadius: '20px',
          bgcolor: '#fff',
        }}
        size="small"
        value={selectLang}
        onChange={(event) => handleChange(event)}
        disableUnderline
      >
        {user?.brand.languages?.map((lang) => (
          <MenuItem sx={{ display: 'flex', alignItems: 'center', wordSpacing: '5px' }} key={lang.code} value={lang.code}>
            <span style={{ marginRight: '5px', borderRadius: '5px' }}>{flagIcons[lang.code]}</span>
            {lang.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LanguageSelector;
