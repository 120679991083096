
import { useCallback } from 'react';

const OFFLINE_MESSAGE = 'You are offline. Please check your internet connection.';

const useCheckOnlineStatus = (setSnackBar) => {
  const checkOnlineStatus = useCallback(() => {
    const isOnline = navigator.onLine;
    if (!isOnline) {
      setSnackBar({ open: true, message: OFFLINE_MESSAGE });
    }
    return isOnline; 
  }, [setSnackBar]);

  return checkOnlineStatus;
};

export default useCheckOnlineStatus;
