import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import httpService from '../service/http.service';
import { RestUrlsConstants } from '../constants/rest-urls.constants';

const initialState = {
  suppliers: [],
  loading: false,
  error: null,
};

export const fetchSupplier = createAsyncThunk('suppliers/fetchSupplier', async (_, { rejectWithValue }) => {
  try {
    const response = await httpService.get(`${RestUrlsConstants.supplier}`, {
      headers: {
        Authorization: localStorage.getItem('Authorization'),
        'Content-Type': 'application/json',
      },
    });
    return response?.data?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const fetchSupplierWithSearch = createAsyncThunk('suppliers/fetchSupplierWithSearch', async ({ term }, { rejectWithValue }) => {
  try {
    const response = await httpService.get(`${RestUrlsConstants.supplier}/?query=${term}`, {
      headers: {
        Authorization: localStorage.getItem('Authorization'),
        'Content-Type': 'application/json',
      },
    });
    return response?.data?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

export const fetchSupplierWithCountry = createAsyncThunk('suppliers/fetchSupplierWithCountry', async ({ page, itemsPerPage, country }, { rejectWithValue }) => {
  try {
    const response = await httpService.get(`${RestUrlsConstants.supplier}/?&page=${page}&limit=${itemsPerPage}&country=${country}`, {
      headers: {
        Authorization: localStorage.getItem('Authorization'),
        'Content-Type': 'application/json',
      },
    });
    return response?.data?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});
export const fetchSupplierWithProducts = createAsyncThunk('suppliers/hasProducts', async ({ page, itemsPerPage, product }, { rejectWithValue }) => {
  try {
    const response = await httpService.get(`${RestUrlsConstants.supplier}/?&page=${page}&limit=${itemsPerPage}&products=${product === 'Yes' ? true : false}`, {
      headers: {
        Authorization: localStorage.getItem('Authorization'),
        'Content-Type': 'application/json',
      },
    });
    return response?.data?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});
export const fetchSupplierWithOutProduct = createAsyncThunk('suppliers/hasSupplyChain', async ({ page, itemsPerPage, product }, { rejectWithValue }) => {
  try {
    const response = await httpService.get(`${RestUrlsConstants.supplier}/?&page=${page}&limit=${itemsPerPage}&supplyChain=${product === 'Yes' ? true : false}`, {
      headers: {
        Authorization: localStorage.getItem('Authorization'),
        'Content-Type': 'application/json',
      },
    });
    return response?.data?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});
export const fetchSupplierWithSort = createAsyncThunk('suppliers/fetchSupplierWithSort', async ({ page, itemsPerPage, sort }, { rejectWithValue }) => {
  try {
    const response = await httpService.get(`${RestUrlsConstants.supplier}/?&page=${page}&limit=${itemsPerPage}&sortBy=${sort}&orderBy=${'asc'}`, {
      headers: {
        Authorization: localStorage.getItem('Authorization'),
        'Content-Type': 'application/json',
      },
    });
    return response?.data?.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data);
  }
});

const supplierSlice = createSlice({
  name: 'supplier',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSupplier.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSupplier.fulfilled, (state, action) => {
        state.loading = false;
        state.suppliers = action.payload;
      })
      .addCase(fetchSupplier.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchSupplierWithSearch.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSupplierWithSearch.fulfilled, (state, action) => {
        state.loading = false;
        state.suppliers = action.payload;
      })
      .addCase(fetchSupplierWithSearch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchSupplierWithProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSupplierWithProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.suppliers = action.payload;
      })
      .addCase(fetchSupplierWithProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchSupplierWithCountry.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSupplierWithCountry.fulfilled, (state, action) => {
        state.loading = false;
        state.suppliers = action.payload;
      })
      .addCase(fetchSupplierWithCountry.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchSupplierWithOutProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSupplierWithOutProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.suppliers = action.payload;
      })
      .addCase(fetchSupplierWithOutProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchSupplierWithSort.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSupplierWithSort.fulfilled, (state, action) => {
        state.loading = false;
        state.suppliers = action.payload;
      })
      .addCase(fetchSupplierWithSort.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default supplierSlice.reducer;
