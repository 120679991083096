import { useEffect, useState } from 'react';
import httpService from '../service/http.service';
import { RestUrlsConstants } from '../constants/rest-urls.constants';
import { useDispatch } from 'react-redux';
import { addUser, removeUser } from '../features/userSlice';
import { useNavigate } from 'react-router-dom';

const Auth = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const handleClose = () => setsnackBar({ ...snackBar, open: false });
  const [snackBar, setsnackBar] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const douthentiction = () => {
    const token = localStorage.getItem('Authorization');
    if (token) {
      httpService
        .get(RestUrlsConstants.authUrl, { headers: { Authorization: localStorage.getItem('Authorization') } })
        .then((data) => {
          dispatch(
            addUser({
              id: data.data.data.id,
              email: data.data.data.email,
              name: data.data.data.name,
              brand: data.data.data.brand,
              formstatus: data.data.data.formstatus,
              resources: data.data.data.resources,
              userType: data.data.data.userType,
              languages:data.data.data.languages,
              defaultLanguage:data.data.data.defaultLanguage
            })
          );
          if (!data?.data?.status) {
            navigation('/login');
          }
        })
        .catch((e) => {
          setsnackBar({ ...snackBar, open: true });
          localStorage.removeItem('Authorization');
          localStorage.removeItem('resources');
          dispatch(removeUser());
        });
    } else {
      navigation('/login');
    }
  };

  useEffect(() => {
    douthentiction();
  }, []);
  return { snackBar, handleClose, douthentiction, setsnackBar };
};

export default Auth;
